// utils
import { filter, sample } from "lodash";
import { useState, useEffect, useRef } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import { collection, doc, onSnapshot, setDoc } from "firebase/firestore";
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  MenuItem,
  Menu,
  Modal,
  Backdrop,
  Fade,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Alert,
  AlertTitle,
} from "@mui/material";
import { db, auth, app, functions } from "../Firebase/firebase";
// material
// components
import Page from "../components/Page";
import Label from "../components/Label";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../components/_dashboard/user";
import { mockImgAvatar } from "../utils/mockImages";
import { fCurrency } from "../utils/formatNumber";
import { Box } from "@mui/system";
import { httpsCallable } from "firebase/functions";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "accountName", label: "Account Name", alignRight: false },
  { id: "accountNumber", label: "Account Number", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "mainBalance", label: "Balance", alignRight: false },
  { id: "checkingBalance", label: "Checking Balance", alignRight: false },
  { id: "marketAccount", label: "Market Account", alignRight: false },
  { id: "isTransferEnabled", label: "Account Status", alignRight: false },
  { id: "phoneNumber", label: "Phone Number", alignRight: false },
  { id: "address", label: "Address", alignRight: false },
  { id: "country", label: "Country", alignRight: false },
  { id: "zipCode", label: "Zip Code", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  const [currentUser, setCurrentUser] = useState();
  const [adminToken, setAdminToken] = useState();
  const [usersList, setUsersList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          if (!idTokenResult.claims.admin) {
            navigate("/login");
          }
        });
        setCurrentUser(user);
      } else {
        navigate("/login");
      }
    });
  }, [navigate]);

  useEffect(() => {
    const unSubscribe = onSnapshot(collection(db, "users"), (snapshot) => {
      setUsersList(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
      );
    });
    return unSubscribe;
  }, []);

  const users = usersList.map((user) => ({
    id: user.id,
    accountName: user.accountName,
    accountNumber: user.accountNumber,
    email: user.email,
    mainBalance: user.mainBalance,
    checkingBalance: user.checkingBalance,
    marketAccount: user.marketAccount,
    isTransferEnabled: user.isTransferEnabled,
    phoneNumber: user.phoneNumber,
    address: user.address,
    country: user.country,
    zipCode: user.zipCode,
  }));

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const filteredUsers = applySortFilter(
    users,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  // add user
  const [openAddUserModel, setOpenAddUserModel] = useState(false);
  const [updateButton, setUpdateButton] = useState("Add user");
  const [accStatus, setAccStatus] = useState(true);
  const [newUserAccountName, setNewUserAccountName] = useState("");
  // const [newUserAccountNumber, setNewUserAccountNumber] = useState("");
  const [newUserAccountEmail, setNewUserAccountEmail] = useState("");
  const [newUserAccountPhone, setNewUserAccountPhone] = useState("");
  const [newUserAccountAddress, setNewUserAccountAddress] = useState("");
  const [newUserAccountCountry, setNewUserAccountCountry] = useState("");
  const [newUserAccountZip, setNewUserAccountZip] = useState("");
  const [newUserAccountPassword, setNewUserAccountPassword] = useState("");
  const [addUserAlert, setaddUserAlert] = useState("");

  const handleOpenAddUserModel = () => setOpenAddUserModel(true);

  const handleCloseAddUserModel = () => {
    setOpenAddUserModel(false);
    setaddUserAlert("");
  };

  // Modal Styles
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    overflow: "scroll",
    height: "100%",
    display: "block",
    bgcolor: "background.paper",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 400,
    border: "none",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };

  // position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   width: "90%",
  //   maxWidth: 400,
  //   bgcolor: "background.paper",
  //   border: "none",
  //   borderRadius: "5px",
  //   boxShadow: 24,
  //   p: 4,
  //   display: "block",
  //   flexDirection: "column",
  //   overflow: "scroll",

  const changeStatusType = (e) => {
    if (e.target.value === "true") {
      setAccStatus(true);
    } else if (e.target.value === "false") {
      setAccStatus(false);
    }
  };

  var data = {
    email: newUserAccountEmail,
    password: newUserAccountPassword,
  };

  const createUser = httpsCallable(functions, "createUser");

  // Tip: assign your external references to variables instead of chaining off the curly brackets.
  function generate(n) {
    var add = 1,
      max = 12 - add; // 12 is the min safe number Math.random() can generate without it starting to pad the end with zeros.

    if (n > max) {
      return generate(max) + generate(n - max);
    }

    max = Math.pow(10, n + add);
    var min = max / 10; // Math.pow(10, n) basically
    var number = Math.floor(Math.random() * (max - min + 1)) + min;

    return ("" + number).substring(add);
  }

  // Add user function
  const handleCreate = () => {
    setUpdateButton("Loading...");
    createUser(data).then(function (result) {
      setDoc(doc(db, "users", result.data.response.uid), {
        accountName: newUserAccountName,
        accountNumber: generate(10),
        address: newUserAccountAddress,
        checkingBalance: 0,
        country: newUserAccountCountry,
        email: newUserAccountEmail,
        isTransferEnabled: accStatus,
        mainBalance: 0,
        marketAccount: 0,
        phoneNumber: newUserAccountPhone,
        zipCode: newUserAccountZip,
      })
        .then(() => {
          setUpdateButton("Add User");
          setaddUserAlert("User has been created");
        })
        .catch((err) => {
          setUpdateButton("Add User");
          console.log(err.message);
          alert("an error has occurred");
        });
    });
  };

  return (
    <div>
      <Page title="User | Dashboard">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Users
            </Typography>
          </Stack>

          <div style={{ margin: "30px 0" }}>
            <Button variant="contained" onClick={handleOpenAddUserModel}>
              Add user
            </Button>
          </div>

          <div>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={openAddUserModel}
              onClose={handleCloseAddUserModel}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openAddUserModel}>
                <Box component="form" sx={style} noValidate autoComplete="off">
                  <div style={{ margin: "0 0 5px 0", textAlign: "center" }}>
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        marginBottom: "5px",
                      }}
                    >
                      Add a new user
                    </p>

                    {addUserAlert && (
                      <Alert severity="success">
                        <AlertTitle>Success</AlertTitle>
                        {addUserAlert}
                      </Alert>
                    )}

                    <TextField
                      label="Account Name"
                      style={{ margin: "0 0 10px 0", width: "90%" }}
                      onChange={(e) => setNewUserAccountName(e.target.value)}
                    />

                    <TextField
                      label="Email Address"
                      style={{ margin: "0 0 10px 0", width: "90%" }}
                      onChange={(e) => setNewUserAccountEmail(e.target.value)}
                    />

                    <TextField
                      label="Password"
                      style={{ margin: "0 0 10px 0", width: "90%" }}
                      onChange={(e) =>
                        setNewUserAccountPassword(e.target.value)
                      }
                      type="password"
                    />

                    <TextField
                      label="Phone Number"
                      style={{ margin: "0 0 10px 0", width: "90%" }}
                      onChange={(e) => setNewUserAccountPhone(e.target.value)}
                    />

                    <TextField
                      label="Address"
                      style={{ margin: "0 0 10px 0", width: "90%" }}
                      onChange={(e) => setNewUserAccountAddress(e.target.value)}
                    />

                    <TextField
                      label="Country"
                      style={{ margin: "0 0 10px 0", width: "90%" }}
                      onChange={(e) => setNewUserAccountCountry(e.target.value)}
                    />

                    <TextField
                      label="ZipCode"
                      style={{ margin: "0 0 10px 0", width: "90%" }}
                      onChange={(e) => setNewUserAccountZip(e.target.value)}
                    />

                    <FormControl>
                      <FormLabel id="demo-controlled-radio-buttons-group">
                        Account Status
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        onChange={(e) => changeStatusType(e)}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Activate Transfer"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="De-activate Transfer"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={handleCreate}
                      disabled={updateButton === "Add user" ? false : true}
                      style={{ width: "100%" }}
                    >
                      {updateButton}
                    </Button>
                  </div>
                </Box>
              </Fade>
            </Modal>
          </div>

          <Card>
            <UserListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={users.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        const {
                          id,
                          accountName,
                          accountNumber,
                          email,
                          mainBalance,
                          checkingBalance,
                          marketAccount,
                          isTransferEnabled,
                          phoneNumber,
                          address,
                          country,
                          zipCode,
                        } = row;

                        return (
                          <TableRow hover key={accountName} tabIndex={-1}>
                            <TableCell align="left">{accountName}</TableCell>
                            <TableCell align="left">{accountNumber}</TableCell>
                            <TableCell align="left">{email}</TableCell>
                            <TableCell align="left">
                              {fCurrency(mainBalance)}
                            </TableCell>
                            <TableCell align="left">
                              {fCurrency(checkingBalance)}
                            </TableCell>
                            <TableCell align="left">
                              {fCurrency(marketAccount)}
                            </TableCell>
                            <TableCell align="left">
                              {isTransferEnabled
                                ? "Transfer Activated"
                                : "Transfer De-Activated"}
                            </TableCell>
                            <TableCell align="left">{phoneNumber}</TableCell>
                            <TableCell align="left">{address}</TableCell>
                            <TableCell align="left">{country}</TableCell>
                            <TableCell align="left">{zipCode}</TableCell>

                            <TableCell align="right">
                              <UserMoreMenu
                                uid={id}
                                email={email}
                                accountName={accountName}
                                mainBalance={mainBalance}
                                checkingBalance={checkingBalance}
                                marketAccount={marketAccount}
                                accountStatus={isTransferEnabled}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={users.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>
    </div>
  );
}
