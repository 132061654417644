// utils
import { filter } from "lodash";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  onSnapshot,
  query,
  setDoc,
  Timestamp,
  orderBy as firebaseOrderBy,
} from "firebase/firestore";
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  Backdrop,
  Modal,
  Fade,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { db, auth } from "../Firebase/firebase";
// material
// components
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import { UserListHead, UserMoreMenu2, UserMoreMenu3 } from "../components/_dashboard/user";
import { useParams } from "react-router-dom";
import { fCurrency } from "../utils/formatNumber";
import { Box, width } from "@mui/system";
import { DatePicker } from "react-responsive-datepicker";
import "react-responsive-datepicker/dist/index.css";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "amount", label: "Amount", alignRight: false },
  { id: "date", label: "Date", alignRight: false },
  { id: "desc", label: "Description", alignRight: false },
  {
    id: "receiverAccountName",
    label: "Receiver Account Name",
    alignRight: false,
  },
  {
    id: "receiverAccountNumber",
    label: "Receiver Account Number",
    alignRight: false,
  },
  {
    id: "transactionOTP",
    label: "Transaction OTP",
    alignRight: false,
  },
  {
    id: "transactionType",
    label: "Transaction Type",
    alignRight: false,
  },
  {
    id: "transactionId",
    label: "Transaction Id",
    alignRight: false,
  },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserDetails() {
  const [currentUser, setCurrentUser] = useState();
  const [usersList, setUsersList] = useState([]);
  const navigate = useNavigate();
  const { uid } = useParams();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          if (!idTokenResult.claims.admin) {
            navigate("/login");
          }
        });
        setCurrentUser(user);
      } else {
        navigate("/login");
      }
    });
  }, [navigate]);

  useEffect(() => {
    const q = query(
      collection(db, `users/${uid}/transactions`),
      firebaseOrderBy("date", "desc")
    );

    const unSubscribe = onSnapshot(q, (snapshot) => {
      const dbUsers = [];
      snapshot.forEach((doc) => {
        dbUsers.push({
          transactionId: doc.id,
          ...doc.data(),
        });
      });
      setUsersList(dbUsers);
    });

    return unSubscribe;
  }, [uid]);

  const users = usersList.map((user) => ({
    id: uid,
    amount: user.amount,
    date: user.date,
    desc: user.desc,
    receiverAccountName: user.receiverAccountName,
    receiverAccountNumber: user.receiverAccountNumber,
    transactionOTP: user.transactionOTP,
    transactionType: user.transactionType,
    transactionId: user.transactionId,
  }));

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const filteredUsers = applySortFilter(
    users,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  // Modal
  const [openAddUserModel, setOpenAddUserModel] = useState(false);
  const [addUserAlert, setaddUserAlert] = useState("");
  const [updateButton, setUpdateButton] = useState("Add transaction");
  const [amount, setAmount] = useState(0);
  const [receiversAccountName, setReceiversAccountName] = useState("");
  const [receiversAccountNumber, setReceiversAccountNumber] = useState(0);
  const [transactionType, setTransactionType] = useState("");
  const [day, setDay] = useState();
  const [month, setmonth] = useState();
  const [year, setYear] = useState();
  const [description, setDescription] = useState("");

  const handleOpenAddUserModel = () => setOpenAddUserModel(true);

  const handleCloseAddUserModel = () => {
    setOpenAddUserModel(false);
    setaddUserAlert("");
  };

  // Modal Styles
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    overflow: "scroll",
    height: "auto",
    display: "block",
    bgcolor: "background.paper",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 400,
    border: "none",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };

  const handleCreate = () => {
    setUpdateButton("Loading...");
    var mydate = new Date(`${year}-${month}-${day}T00:00:00`);
    const fireTmeStamp = Timestamp.fromDate(mydate);

    addDoc(collection(db, `users/${uid}/transactions`), {
      amount: amount,
      date: fireTmeStamp,
      desc: description,
      receiverAccountName: receiversAccountName,
      receiverAccountNumber: receiversAccountNumber,
      transactionType: transactionType,
    })
      .then(() => {
        setUpdateButton("Add transaction");
        alert("Transaction created");
      })
      .catch((err) => {
        setUpdateButton("Add transaction");
        alert("an error has occurred");
        console.log(err);
      });
  };
  return (
    <div>
      <Page title="User | Dashboard">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Transaction Details
            </Typography>
          </Stack>

          <div style={{ margin: "30px 0" }}>
            <Button variant="contained" onClick={handleOpenAddUserModel}>
              Add Transaction
            </Button>
          </div>

          <div>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={openAddUserModel}
              onClose={handleCloseAddUserModel}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openAddUserModel}>
                <Box component="form" sx={style} noValidate autoComplete="off">
                  <div style={{ margin: "0 0 5px 0", textAlign: "center" }}>
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        marginBottom: "5px",
                      }}
                    >
                      Add a new transaction
                    </p>

                    <TextField
                      label="Amount"
                      style={{ margin: "0 0 10px 0", width: "90%" }}
                      onChange={(e) => setAmount(e.target.value)}
                      type="number"
                    />

                    <TextField
                      label="Receivers Account Name"
                      style={{ margin: "0 0 10px 0", width: "90%" }}
                      onChange={(e) => setReceiversAccountName(e.target.value)}
                    />

                    <TextField
                      label="Receivers Account Number"
                      style={{ margin: "0 0 10px 0", width: "90%" }}
                      onChange={(e) =>
                        setReceiversAccountNumber(e.target.value)
                      }
                      type="number"
                    />

                    <FormControl
                      style={{
                        margin: "0 0 10px 0",
                        width: "30%",
                        marginRight: "5px",
                      }}
                    >
                      <InputLabel id="demo-simple-select-label">Day</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Day"
                        onChange={(e) => setDay(e.target.value)}
                      >
                        <MenuItem value="01">01</MenuItem>
                        <MenuItem value="02">02</MenuItem>
                        <MenuItem value="03">03</MenuItem>
                        <MenuItem value="04">04</MenuItem>
                        <MenuItem value="05">05</MenuItem>
                        <MenuItem value="06">06</MenuItem>
                        <MenuItem value="07">07</MenuItem>
                        <MenuItem value="08">08</MenuItem>
                        <MenuItem value="09">09</MenuItem>
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="11">11</MenuItem>
                        <MenuItem value="12">12</MenuItem>
                        <MenuItem value="13">13</MenuItem>
                        <MenuItem value="14">14</MenuItem>
                        <MenuItem value="15">15</MenuItem>
                        <MenuItem value="16">16</MenuItem>
                        <MenuItem value="17">17</MenuItem>
                        <MenuItem value="18">18</MenuItem>
                        <MenuItem value="19">19</MenuItem>
                        <MenuItem value="20">20</MenuItem>
                        <MenuItem value="21">21</MenuItem>
                        <MenuItem value="22">22</MenuItem>
                        <MenuItem value="23">23</MenuItem>
                        <MenuItem value="24">24</MenuItem>
                        <MenuItem value="25">25</MenuItem>
                        <MenuItem value="26">26</MenuItem>
                        <MenuItem value="27">27</MenuItem>
                        <MenuItem value="28">28</MenuItem>
                        <MenuItem value="29">29</MenuItem>
                        <MenuItem value="30">30</MenuItem>
                        <MenuItem value="31">31</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl
                      style={{
                        margin: "0 0 10px 0",
                        width: "30%",
                        marginRight: "5px",
                      }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Month
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Month"
                        onChange={(e) => setmonth(e.target.value)}
                      >
                        <MenuItem value="01">Jan</MenuItem>
                        <MenuItem value="02">Feb</MenuItem>
                        <MenuItem value="03">Mar</MenuItem>
                        <MenuItem value="04">Apr</MenuItem>
                        <MenuItem value="05">May</MenuItem>
                        <MenuItem value="06">Jun</MenuItem>
                        <MenuItem value="07">Jul</MenuItem>
                        <MenuItem value="08">Aug</MenuItem>
                        <MenuItem value="09">Sep</MenuItem>
                        <MenuItem value="10">Oct</MenuItem>
                        <MenuItem value="11">Nov</MenuItem>
                        <MenuItem value="12">Dec</MenuItem>
                      </Select>
                    </FormControl>

                    <TextField
                      label="Year"
                      style={{
                        margin: "0 0 10px 0",
                        width: "30%",
                        // marginRight: "5px",
                      }}
                      onChange={(e) => setYear(e.target.value)}
                      type="number"
                    />

                    <FormControl
                      fullWidth
                      style={{ margin: "0 0 15px 0", width: "90%" }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Select transaction type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select transaction type"
                        onChange={(e) => setTransactionType(e.target.value)}
                      >
                        <MenuItem value="Credit fund">Credit fund</MenuItem>
                        <MenuItem value="Debit fund">Debit fund</MenuItem>
                      </Select>
                    </FormControl>

                    <TextField
                      label="Description"
                      style={{ margin: "0 0 10px 0", width: "90%" }}
                      multiline
                      maxRows={4}
                      onChange={(e) => setDescription(e.target.value)}
                      type="text"
                    />

                    <Button
                      variant="contained"
                      onClick={handleCreate}
                      disabled={
                        updateButton === "Add transaction" ? false : true
                      }
                      style={{ width: "100%" }}
                    >
                      {updateButton}
                    </Button>
                  </div>
                </Box>
              </Fade>
            </Modal>
          </div>

          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={users.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        const {
                          id,
                          amount,
                          date,
                          desc,
                          receiverAccountName,
                          receiverAccountNumber,
                          transactionOTP,
                          transactionType,
                          transactionId,
                        } = row;

                        const transactionDate = new Date(date.seconds * 1000)
                          .toISOString()
                          .substring(0, 10);

                        return (
                          <TableRow hover key={transactionId} tabIndex={-1}>
                            <TableCell align="left">
                              {fCurrency(amount)}
                            </TableCell>
                            <TableCell align="left">
                              {transactionDate}
                            </TableCell>
                            <TableCell align="left">{desc}</TableCell>
                            <TableCell align="left">
                              {receiverAccountName}
                            </TableCell>
                            <TableCell align="left">
                              {receiverAccountNumber}
                            </TableCell>
                            <TableCell align="left">{transactionOTP}</TableCell>
                            <TableCell align="left">
                              {transactionType}
                            </TableCell>
                            <TableCell align="left">{transactionId}</TableCell>

                            <TableCell align="right">
                              <UserMoreMenu2
                                amount={amount}
                                date={date}
                                desc={desc}
                                receiverAccountName={receiverAccountName}
                                receiverAccountNumber={receiverAccountNumber}
                                transactionType={transactionType}
                                transactionId={transactionId}
                                uid={uid}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={users.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>
    </div>
  );
}
