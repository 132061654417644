import { Icon } from "@iconify/react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { httpsCallable } from "firebase/functions";
import { useRef, useState } from "react";
import editFill from "@iconify/icons-eva/edit-fill";
import { Link as RouterLink } from "react-router-dom";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import moreVerticalFill from "@iconify/icons-eva/more-vertical-fill";
import activityFill from "@iconify/icons-eva/activity-fill";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { doc, updateDoc, addDoc, collection } from "firebase/firestore";
import { db, functions } from "../../../Firebase/firebase";

// ----------------------------------------------------------------------

// Modal Styles
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 400,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
};

export default function UserMoreMenu({
  uid,
  accountName,
  email,
  mainBalance,
  accountStatus,
  marketAccount,
  checkingBalance,
}) {
  const deletedUserUID = uid;
  const deletedUserEmail = email;
  // User balance states

  const [updateButton, setUpadateButton] = useState("Update");
  const [userMainBalance, setUserMainBalance] = useState(mainBalance);
  const [accStatus, setAccStatus] = useState(accountStatus);
  const [userMarketAccount, setUserMarketAccount] = useState(marketAccount);
  const [userCheckingBalance, setUserCheckingBalance] =
    useState(checkingBalance);
  const [userAccountName, setUserAccountName] = useState(accountName);
  const [userEmail, setUserEmail] = useState(email);

  const [updateAlert, setUpdateAlert] = useState("");
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  // Delete user cloud function
  const deleteUser = httpsCallable(functions, "deleteUser");

  const handleDelete = (uid) => {
    deleteUser({ uid }).then((result) => {
      console.log("User delete successfully", result);
    });
  };

  const changeStatusType = (e) => {
    if (e.target.value === "true") {
      setAccStatus(true);
    } else if (e.target.value === "false") {
      setAccStatus(false);
    } else {
      setAccStatus(accountStatus);
    }
  };

  // Modal states

  const [open, setOpen] = useState(false);

  const handleUpdate = async (uid) => {
    setUpadateButton("Updating...");
    const userRef = doc(db, "users", uid);
    await updateDoc(userRef, {
      email: userEmail,
      accountName: userAccountName,
      mainBalance: Number(userMainBalance),
      checkingBalance: Number(userCheckingBalance),
      marketAccount: Number(userMarketAccount),
      isTransferEnabled: accStatus,
    })
      .then(() => {
        setUpadateButton("Update");
        setUpdateAlert("User account has been updated");
      })
      .catch((err) => {
        setUpadateButton("Update");
        console.log(err.message);
      });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setUpdateAlert("");
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          sx={{ color: "text.secondary" }}
          onClick={() => handleDelete(deletedUserUID)}
        >
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>

        <MenuItem
          component={RouterLink}
          to="#"
          sx={{ color: "text.secondary" }}
          onClick={handleOpen}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Edit"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box component="form" sx={style} noValidate autoComplete="off">
              <div style={{ margin: "0 0 5px 0", textAlign: "center" }}>
                {updateAlert && (
                  <Alert severity="success">
                    <AlertTitle>Success</AlertTitle>
                    {updateAlert}
                  </Alert>
                )}

                <TextField
                  label="Account Name"
                  id="outlined-size-normal"
                  style={{ margin: "0 0 15px 0", width: "90%" }}
                  defaultValue={accountName}
                  onChange={(e) => setUserAccountName(e.target.value)}
                />

                <TextField
                  label="User's email"
                  id="outlined-size-normal"
                  style={{ margin: "0 0 15px 0", width: "90%" }}
                  defaultValue={deletedUserEmail}
                  disabled
                  onChange={(e) => setUserEmail(e.target.value)}
                />

                <TextField
                  label="Balance"
                  id="outlined-size-normal"
                  style={{ margin: "0 0 15px 0", width: "90%" }}
                  onChange={(e) => setUserMainBalance(e.target.value)}
                  type="number"
                />

                <TextField
                  label="Checking Balance"
                  id="outlined-size-normal"
                  style={{ margin: "0 0 15px 0", width: "90%" }}
                  onChange={(e) => setUserCheckingBalance(e.target.value)}
                  type="number"
                />

                <TextField
                  label="Market Account"
                  id="outlined-size-normal"
                  style={{ margin: "0 0 15px 0", width: "90%" }}
                  onChange={(e) => setUserMarketAccount(e.target.value)}
                  type="number"
                />

                <FormControl>
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    Account Status
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    defaultValue={accountStatus}
                    onChange={(e) => changeStatusType(e)}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Activate Transfer"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="De-activate Transfer"
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              <Button
                variant="contained"
                onClick={() => handleUpdate(deletedUserUID)}
                disabled={updateButton === "Update" ? false : true}
              >
                {updateButton}
              </Button>
            </Box>
          </Fade>
        </Modal>

        <MenuItem
          component={RouterLink}
          to={"userDetails/" + deletedUserUID}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Icon icon={activityFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="View transactions"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
      </Menu>
    </>
  );
}
