// utils
import { filter, sample } from "lodash";
import { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { collection, onSnapshot } from "firebase/firestore";
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
import { db, auth, app, functions } from "../Firebase/firebase";
// material
// components
import Page from "../components/Page";
import Label from "../components/Label";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
  UserMoreMenu3,
} from "../components/_dashboard/user";
import { mockImgAvatar } from "../utils/mockImages";
import { fCurrency } from "../utils/formatNumber";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "planName", label: "Plan Name", alignRight: false },
  { id: "minimumAmount", label: "Minimum Amount", alignRight: false },
  { id: "maximumAmount", label: "Maximum Amount", alignRight: false },
  { id: "daysOfInvestment", label: "Days Of Investment", alignRight: false },
  { id: "dailyProfit", label: "Daily Profit (%)", alignRight: false },
  { id: "activationBonus", label: "Activation Bonus", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Plans() {
  const [currentUser, setCurrentUser] = useState();
  const [adminToken, setAdminToken] = useState();
  const [usersList, setUsersList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          if (!idTokenResult.claims.admin) {
            navigate("/login");
          }
        });
        setCurrentUser(user);
      } else {
        navigate("/login");
      }
    });
  }, [navigate]);

  useEffect(() => {
    const unSubscribe = onSnapshot(
      collection(db, `siteConfigData/plans/plan1`),
      (snapshot) => {
        setUsersList(snapshot.docs.map((doc) => doc.data()));
      }
    );
    return unSubscribe;
  }, []);

  console.log(usersList);

  const users = usersList.map((user) => ({
    id: user.planId,
    planName: user.planName,
    minimumAmount: user.minAmount,
    maximumAmount: user.maxAmount,
    daysOfInvestment: user.daysOfInvestment,
    dailyProfit: user.dailyProfit,
    activationBonus: user.activationBonus,
  }));

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const filteredUsers = applySortFilter(
    users,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <div>
      <Page title="User | Dashboard">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Plans
            </Typography>
          </Stack>

          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={users.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        const {
                          id,
                          planName,
                          minimumAmount,
                          maximumAmount,
                          daysOfInvestment,
                          dailyProfit,
                          activationBonus,
                        } = row;

                        return (
                          <TableRow hover key={planName} tabIndex={-1}>
                            <TableCell align="left">{planName}</TableCell>
                            <TableCell align="left">
                              {fCurrency(minimumAmount)}
                            </TableCell>
                            <TableCell align="left">
                              {fCurrency(maximumAmount)}
                            </TableCell>
                            <TableCell align="left">
                              {daysOfInvestment} days
                            </TableCell>
                            <TableCell align="left">{dailyProfit}%</TableCell>
                            <TableCell align="left">
                              {fCurrency(activationBonus)}
                            </TableCell>

                            <TableCell align="right">
                              <UserMoreMenu3
                                id={id}
                                planName={planName}
                                minimumAmount={minimumAmount}
                                maximumAmount={maximumAmount}
                                daysOfInvestment={daysOfInvestment}
                                dailyProfit={dailyProfit}
                                activationBonus={activationBonus}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={users.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>
    </div>
  );
}
