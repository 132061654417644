import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyApPjvUzWvoWtnNb716kT7Nu3aDnDAA888",
  authDomain: "dresdenag-3c2ef.firebaseapp.com",
  projectId: "dresdenag-3c2ef",
  storageBucket: "dresdenag-3c2ef.appspot.com",
  messagingSenderId: "1055337189295",
  appId: "1:1055337189295:web:4cffcff5b3282673b6a95a",
};

// apiKey: "AIzaSyCN2AheH_fLcTjHNKyLv7tqOujbmfonS0I",
//   authDomain: "rn-blockchain.firebaseapp.com",
//   projectId: "rn-blockchain",
//   storageBucket: "rn-blockchain.appspot.com",
//   messagingSenderId: "802897162818",
//   appId: "1:802897162818:web:d210a6b320276c9bb273db",

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app);

export { db, auth, app, functions };
