import { Icon } from "@iconify/react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { useEffect, useRef, useState } from "react";
import editFill from "@iconify/icons-eva/edit-fill";
import { Link as RouterLink } from "react-router-dom";
import moreVerticalFill from "@iconify/icons-eva/more-vertical-fill";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  onSnapshot,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../Firebase/firebase";

// ----------------------------------------------------------------------

// Modal Styles
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 400,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
};

export default function UserMoreMenu2({
  uid,
  amount,
  date,
  desc,
  receiverAccountName,
  receiverAccountNumber,
  transactionType,
  transactionId,
}) {
  // User balance states

  const [transAmount, setTransAmount] = useState(amount);
  const [transDate, setTransDate] = useState(date);
  const [transDesc, setTransDesc] = useState(desc);
  const [transReceiverAccountName, setTransReceiverAccountName] =
    useState(receiverAccountName);
  const [transReceiverAccountNumber, setTransReceiverAccountNumber] = useState(
    receiverAccountNumber
  );
  const [transActionType, setTransActionType] = useState(transactionType);
  const [updateAlert, setUpdateAlert] = useState("");
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const transactionDate = new Date(date.seconds * 1000)
    .toISOString()
    .substring(0, 10);

  const d = new Date(transactionDate);

  const transactionYear = String(d.getFullYear()).padStart(2, "0");
  const transactionMonth = String(d.getMonth() + 1).padStart(2, "0");
  const transactionDay = String(d.getDate()).padStart(2, "0");

  const [day, setDay] = useState(transactionDay);
  const [month, setmonth] = useState(transactionMonth);
  const [year, setYear] = useState(transactionYear);

  // Modal states

  const [open, setOpen] = useState(false);

  const [updateButton, setUpdateButton] = useState("Update");

  const handleUpdate = async (transactionId) => {
    var mydate = new Date(`${year}-${month}-${day}T00:00:00`);
    const fireTmeStamp = Timestamp.fromDate(mydate);

    setUpdateButton("Updating...");
    const adminRef = doc(db, `users/${uid}/transactions/${transactionId}`);
    await updateDoc(adminRef, {
      amount: transAmount,
      date: fireTmeStamp,
      desc: transDesc,
      receiverAccountName: transReceiverAccountName,
      receiverAccountNumber: transReceiverAccountNumber,
      transactionType: transActionType,
    })
      .then(() => {
        setUpdateButton("Update");
        setUpdateAlert("Transaction details have been updated");
      })
      .catch((err) => {
        setUpdateButton("Update");
        console.log(err.message);
      });
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setUpdateAlert("");
    setTransAmount(amount);
    setTransDesc(desc);
    setTransReceiverAccountName(receiverAccountName);
    setTransReceiverAccountNumber(receiverAccountNumber);
    setTransActionType(transActionType);
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          component={RouterLink}
          to="#"
          sx={{ color: "text.secondary" }}
          onClick={handleOpen}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Edit"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box component="form" sx={style} noValidate autoComplete="off">
              <div style={{ margin: "0 0 5px 0", textAlign: "center" }}>
                {updateAlert && (
                  <Alert severity="success">
                    <AlertTitle>Success</AlertTitle>
                    {updateAlert}
                  </Alert>
                )}

                <TextField
                  label="Amount"
                  style={{ margin: "0 0 10px 0", width: "90%" }}
                  onChange={(e) => setTransAmount(e.target.value)}
                  type="number"
                />

                <TextField
                  label="Receivers Account Name"
                  style={{ margin: "0 0 10px 0", width: "90%" }}
                  onChange={(e) => setTransReceiverAccountName(e.target.value)}
                />

                <TextField
                  label="Receivers Account Number"
                  style={{ margin: "0 0 10px 0", width: "90%" }}
                  onChange={(e) =>
                    setTransReceiverAccountNumber(e.target.value)
                  }
                  type="number"
                />

                <FormControl
                  style={{
                    margin: "0 0 10px 0",
                    width: "30%",
                    marginRight: "5px",
                  }}
                >
                  <InputLabel id="demo-simple-select-label">Day</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Day"
                    onChange={(e) => setDay(e.target.value)}
                  >
                    <MenuItem value="01">01</MenuItem>
                    <MenuItem value="02">02</MenuItem>
                    <MenuItem value="03">03</MenuItem>
                    <MenuItem value="04">04</MenuItem>
                    <MenuItem value="05">05</MenuItem>
                    <MenuItem value="06">06</MenuItem>
                    <MenuItem value="07">07</MenuItem>
                    <MenuItem value="08">08</MenuItem>
                    <MenuItem value="09">09</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                    <MenuItem value="12">12</MenuItem>
                    <MenuItem value="13">13</MenuItem>
                    <MenuItem value="14">14</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="16">16</MenuItem>
                    <MenuItem value="17">17</MenuItem>
                    <MenuItem value="18">18</MenuItem>
                    <MenuItem value="19">19</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="21">21</MenuItem>
                    <MenuItem value="22">22</MenuItem>
                    <MenuItem value="23">23</MenuItem>
                    <MenuItem value="24">24</MenuItem>
                    <MenuItem value="25">25</MenuItem>
                    <MenuItem value="26">26</MenuItem>
                    <MenuItem value="27">27</MenuItem>
                    <MenuItem value="28">28</MenuItem>
                    <MenuItem value="29">29</MenuItem>
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="31">31</MenuItem>
                  </Select>
                </FormControl>

                <FormControl
                  style={{
                    margin: "0 0 10px 0",
                    width: "30%",
                    marginRight: "5px",
                  }}
                >
                  <InputLabel id="demo-simple-select-label">Month</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Month"
                    onChange={(e) => setmonth(e.target.value)}
                  >
                    <MenuItem value="01">Jan</MenuItem>
                    <MenuItem value="02">Feb</MenuItem>
                    <MenuItem value="03">Mar</MenuItem>
                    <MenuItem value="04">Apr</MenuItem>
                    <MenuItem value="05">May</MenuItem>
                    <MenuItem value="06">Jun</MenuItem>
                    <MenuItem value="07">Jul</MenuItem>
                    <MenuItem value="08">Aug</MenuItem>
                    <MenuItem value="09">Sep</MenuItem>
                    <MenuItem value="10">Oct</MenuItem>
                    <MenuItem value="11">Nov</MenuItem>
                    <MenuItem value="12">Dec</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  label="Year"
                  style={{
                    margin: "0 0 10px 0",
                    width: "30%",
                    // marginRight: "5px",
                  }}
                  onChange={(e) => setYear(e.target.value)}
                  type="number"
                />

                <FormControl
                  fullWidth
                  style={{ margin: "0 0 15px 0", width: "90%" }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Select transaction type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select transaction type"
                    onChange={(e) => setTransActionType(e.target.value)}
                  >
                    <MenuItem value="Credit fund">Credit fund</MenuItem>
                    <MenuItem value="Debit fund">Debit fund</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  label="Description"
                  style={{ margin: "0 0 10px 0", width: "90%" }}
                  multiline
                  maxRows={4}
                  onChange={(e) => setTransDesc(e.target.value)}
                  type="text"
                />
              </div>

              <Button
                variant="contained"
                onClick={() => handleUpdate(transactionId)}
                disabled={updateButton === "Update" ? false : true}
              >
                {updateButton}
              </Button>
            </Box>
          </Fade>
        </Modal>
      </Menu>
    </>
  );
}
